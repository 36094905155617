@import '../sass/mixin';
@import '../sass/responsive';
@import '../sass/variables';

/** Styles for the mainSection component of the grocers page. */
.background-main-grocers {
    @include background(url('../../assets/grocers/backgrounds/web/bg-image-min.png'), initial, 1090px);


    @include for-size(phone-only) {
        @include background(url('../../assets/grocers/backgrounds/phone/main-phone.png'), initial, auto)
    }

    @include for-size(phone-landscape) {
        @include background(url('../../assets/grocers/backgrounds/web/bg-image-min.png'), initial, auto)
    }

    @include for-size(tablet-portrait-up) {
        @include background(url('../../assets/grocers/backgrounds/web/bg-image-min.png'), initial, 1050px);
    }

    @include for-size(tablet-landscape-up) {
        @include background(url('../../assets/grocers/backgrounds/web/bg-image-min.png'), initial, 920px);
    }
}

/** Styles for the informationSection component of the grocers page. */
.background-information-section {
    @include background(url('../../assets/grocers/backgrounds/web/information-section.png'), initial, 1399px);
    margin-top: -28%;

    @include for-size(phone-only) {
        @include background(url('../../assets/grocers/backgrounds/phone/information.png'), initial, 1100px);
        margin-top: -35%;
    }

    @include for-size(phone-landscape) {
        @include background(url('../../assets/grocers/backgrounds/web/information-section.png'), initial, 1400px);
        margin-top: -80%;
    }

    @include for-size(tablet-portrait-up) {
        @include background(url('../../assets/grocers/backgrounds/web/information-section.png'), initial, 1600px);
        margin-top: -80%;
    }

    @include for-size(tablet-landscape-up) {
        @include background(url('../../assets/grocers/backgrounds/web/information-section.png'), initial, 1650px);
        margin-top: -55%;
    }

    @include for-size(desktop-up) {
        @include background(url('../../assets/grocers/backgrounds/web/information-section.png'), initial, 1200px);
        margin-top: -33%;
    }

}

/** Styles for the mealdecision component of the grocers page. */
.background-meal-decision {
    height: auto;
    margin-bottom: 40px;
}

/** Styles for the EngageShopper component of the grocers page. */
.background-engage {
    @include background(url('../../assets/grocers/backgrounds/web/engage-shopper.svg'), initial, 980px);

    background-color: #f6b91b;

    @include for-size(phone-only) {
        @include background(url('../../assets/grocers/backgrounds/phone/engage-shopper.png'), initial, 1360px)
    }

    @include for-size(phone-landscape) {
        @include background(url('../../assets/grocers/backgrounds/web/engage-shopper.svg'), initial, 680px);
    }

    @include for-size(tablet-portrait-up) {
        @include background(url('../../assets/grocers/backgrounds/web/engage-shopper.svg'), initial, 680px);
    }

    @include for-size(tablet-landscape-up) {
        @include background(url('../../assets/grocers/backgrounds/web/engage-shopper.svg'), initial, 980px);
    }
}

/** Styles for the ActionableInspiration component of the grocers page. */
.background-actionable {
    height: 2140px;

    @include for-size(phone-only) {
        height: 2700px;
    }

    @include for-size(phone-landscape) {
        height: 2630px;
    }

    @include for-size(tablet-portrait-up) {
        height: 1800px;
    }

    @include for-size(tablet-landscape-up) {
        height: 2080px;
    }
}

/** Styles for the TurnkeySolution component of the grocers page. */
.background-turnkey {
    @include background(url('../../assets/grocers/backgrounds/web/turnkey-solution.png'), initial, 800px);

    @include for-size(phone-only) {
        @include background(url('../../assets/grocers/backgrounds/phone/turnkey.png'), initial, 996px);
    }

    @include for-size(phone-landscape) {
        @include background(url('../../assets/grocers/backgrounds/web/turnkey-solution.png'), initial, 1050px);
    }

    @include for-size(tablet-portrait-up) {
        @include background(url('../../assets/grocers/backgrounds/web/turnkey-solution.png'), center, 1000px);
    }
    @include for-size(tablet-landscape-up) {
        @include background(url('../../assets/grocers/backgrounds/web/turnkey-solution.png'), initial, 1000px); 
    }
}