@import '../sass/variables';
@import '../sass/responsive';
@import '../sass/mixin';

.box-paragraph-menu {
    @include text($font-family-nunito-sans, 1.1rem, $font-weight-normal, $dark-blue);
    line-height: 1.33;
    letter-spacing: 0.4px;
    text-align: center;

    @include for-size(phone-landscape) {
        @include text($font-family-nunito-sans, 1.1rem, $font-weight-normal, $dark-blue);
        line-height: 1.33;
        letter-spacing: 0.4px;
        text-align: center;
    }

    @include for-size(tablet-portrait-up) {
        @include text($font-family-nunito-sans, 0.875rem, $font-weight-normal, $dark-blue);
        line-height: 1.29;
        letter-spacing: normal;
        text-align: center;
    }
}
.box-paragraph-menud {
    @include text($font-family-nunito-sans, 1.1rem, $font-weight-normal, $dark-blue);
    line-height: 1.33;
    letter-spacing: 0.4px;
    text-align: center;

    @include for-size(phone-landscape) {
        @include text($font-family-nunito-sans, 1.1rem, $font-weight-normal, $dark-blue);
        line-height: 1.33;
        letter-spacing: 0.4px;
        text-align: center;
    }

    @include for-size(tablet-portrait-up) {
        @include text($font-family-nunito-sans, 0.875rem, $font-weight-normal, $dark-blue);
        line-height: 1.29;
        letter-spacing: normal;
        text-align: center;
    }
}

.box-paragraph {

    @include text($font-family-nunito-sans, 1.12rem, $font-weight-normal, $dark-blue);
    line-height: 1.33;
    letter-spacing: 0.4px;
    text-align: center;

    @include for-size(phone-only) {
        @include text($font-family-nunito-sans, 0.875rem, $font-weight-normal, $dark-blue);
        line-height: 1.29;
        letter-spacing: normal;
        text-align: center;
    }

    @include for-size(phone-landscape) {
        @include text($font-family-nunito-sans, 0.9375rem, $font-weight-normal, $dark-blue);
        line-height: 1.29;
        letter-spacing: normal;
        text-align: center;
    }

    @include for-size(tablet-portrait-up) {
        @include text($font-family-nunito-sans, 0.875rem, $font-weight-normal, $dark-blue);
        line-height: 1.29;
        letter-spacing: normal;
        text-align: center;
    }

    @include for-size(tablet-landscape-up) {
        @include text($font-family-nunito-sans, 1rem, $font-weight-normal, $dark-blue);
        line-height: 1.29;
        letter-spacing: normal;
        text-align: center;
    }
}

.title-square-box {
    @include text($font-family-poppins, 1.5rem, $font-weight-600, $dark-blue);
    text-align: center;
}

.paragraph-black {
    @include text($font-family-nunito-sans, 1.125rem, $font-weight-normal, $dark-blue);
    line-height: 1.33;
    letter-spacing: 0.4px;
}

.opacity-paragraph {
    @include text($font-family-nunito-sans, 1.125rem, $font-weight-normal, $dark-blue);
    line-height: 1.33;
    letter-spacing: 0.4px;
    text-align: center;
    opacity: 0.3;
}

.small-paragraph {
    @include text($font-family-nunito-sans, 0.875rem, $font-weight-normal, $dark-blue);
    text-align: center;
    line-height: 1.29;
    letter-spacing: normal;
}