@import '../sass/variables';
@import '../sass/responsive';
@import '../sass/mixin';

.card-menud {
    @include card(375px, 812px, 24px, $gray, relative);
    z-index: 6;

    @include for-size(phone-only) {
        @include card(236px, 511px, 24px, $gray, relative);
        z-index: 6;
    }

    @include for-size(phone-landscape) {
        @include card(236px, 511px, 24px, $gray, relative);
        z-index: 6;
    }

    @include for-size(tablet-portrait-up) {
        @include card(236px, 511px, 24px, $gray, relative);
        z-index: 6;
    }
}


.rectangle-card-menud {
    @include card(802px, 520px, 12px, $gray, relative);

    @include for-size(phone-only) {
        @include card(347px, 321px, 12px, $gray, relative);
        z-index: 2;
    }

    @include for-size(phone-landscape) {
        @include card(347px, 321px, 12px, $gray, relative);
    }

    @include for-size(tablet-portrait-up) {
        @include card(347px, 321px, 12px, $gray, relative);
    }

    img {
        width: 100%;
    }
}

.rectangular-cards {
    @include card(386px, 386px, 24px, $gray, relative);
    z-index: 3;

    @include for-size(phone-only) {
        @include card(200px, 200px, 16px, $gray, relative);
    }

    @include for-size(phone-landscape) {
        @include card(200px, 200px, 16px, $gray, relative);
    }

    @include for-size(tablet-portrait-up) {
        @include card(200px, 200px, 16px, $gray, relative);
    }

    img {
        width: 100%;
    }
}

.card-position {
    @include card(226px, 490px, 24px, $dark-gray, relative);

    @include for-size(phone-only) {
        @include card(133px, 286px, 12px, $dark-gray, relative);
        z-index: 4;
    }

    @include for-size(phone-landscape) {
        @include card(133px, 286px, 12px, $dark-gray, relative);
    }

    @include for-size(tablet-portrait-up) {
        @include card(133px, 286px, 12px, $dark-gray, relative);
    }

    img {
        width: 100%;
    }
}

.square-cards {
    @include card(200px, 200px, 16px, $dark-gray, relative);

    @include for-size(phone-only) {
        @include card(120px, 120px, 16px, $dark-gray, relative);
    }
}