@import '../sass/variables';
@import '../sass/responsive';
@import '../sass/mixin';

.dimension-image-rectangular {
    @include dimension(24px);

    @include for-size(phone-only) {
        @include dimension(16px);
    }

    @include for-size(phone-landscape) {
        @include dimension(16px);
    }

    @include for-size(tablet-portrait-up) {
        @include dimension(16px);
    }
}

.dimensions-images {
    @include dimension(15px);
}

.dimension-image-square-cards {
    @include for-size(phone-only) {
        height: 100%;
    }
}

.dimension-sm-icon {
    @include for-size(phone-only) {
        width: 88px;
        margin-left: 8%;
    }

    @include for-size(phone-landscape) {
        width: 88px;
    }
}

/** Styles for the TurnkeySolution component of the grocers page. */
.dimension-icon {
    @include for-size(phone-only) {
        width: 77px;
    }

}

/** Styles for the RecipeManagement component of the creators page. */
.image-size {
    @include for-size(phone-only) {
        width: 88px;
    }

}