@import '../sass/variables';
@import '../sass/responsive';
@import '../sass/mixin';

.nav-link-menud {
    @include text($font-family-poppins, 18px, 600, white);
    text-align: $text-align-center;
}

.nav-link-menud-yellow {
    @include text($font-family-poppins, 18px, 600, $yellow);
    text-align: $text-align-center;
    text-decoration: underline;
}

.nav-link-menud-yellow:hover {
    color: #ffff;
}

.nav-link-menud:hover {
    color: $yellow
}

 /** Styles for the MenuPhone component */
 .text-white-item-menu{
    @include for-size(phone-only) {
        @include text($font-family-poppins, 0.875rem, $font-weight-normal, $white);
        text-align: center; 
    }
 }

