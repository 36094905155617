@mixin background($background-image, $background-position, $height, ) {
    background-image: $background-image;
    background-position: $background-position;
    height: $height;
    background-size: cover;
    background-repeat: no-repeat;

}


@mixin text($font-family, $font-size, $font-weight, $color, ) {
    font-family: $font-family;
    font-size: $font-size;
    font-weight: $font-weight;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 1px;
    color: $color !important;
}

@mixin card($width, $height, $border-radius, $background-color, $position) {
    width: $width;
    height: $height;
    border-radius: $border-radius;
    background-color: $background-color;
    position: $position;
    overflow: hidden;
}

@mixin button($width, $height, $border-radius, $border, $background-color) {
    width: $width;
    height: $height;
    border-radius: $border-radius;
    border: $border;
    background-color: $background-color;
}

@mixin box($width, $height, $border-radius, $border) {
    width: $width;
    height: $height;
    border-radius: $border-radius;
    border: $border;

}

@mixin box-header($position, $height, $border-top-right-radius, $border-top-left-radius,
    $background-color, $border-bottom) {
    position: $position;
    height: $height;
    border-top-right-radius: $border-top-right-radius;
    border-top-left-radius: $border-top-left-radius;
    background-color: $background-color;
    border-bottom: $border-bottom;
}

@mixin overflow($overflow-x) {
    overflow-x: $overflow-x;
    overflow-y: hidden;
    position: relative;
    padding-bottom: 20px;
    z-index: 5;
}

@mixin dimension($border-radius){
    width: 100%;
    height: 100%;
    border-radius: $border-radius;
}