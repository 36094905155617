@import '../sass/variables';
@import '../sass/mixin';
@import '../sass/responsive';

.btn-orange-menud {
    @include button(344px, 56px, 36px, $border-orange, $white)
}

.btn-menud {
    @include button(304px, 56px, 36px, $yellow, $yellow);

    @include for-size(phone-only) {
        @include button(319px, 48px, 36px, $yellow, $yellow);
    }
}

.btn-yellow-transparent {
    @include button(304px, 56px, 36px, $border-yellow, $transparent);

    @include for-size(phone-only) {
        @include button(304px, 48px, 36px, $border-yellow, $transparent);
    }
}

.btn-orange-download-menud{
    @include button(236px, 48px, 32px, $orange, $orange);
}