@import '../sass/variables';
@import '../sass/responsive';
@import '../sass/mixin';

// Styles for all orange titles.
.main-title-menud {
    @include text($font-family-poppins, 1.5rem, $font-weight-600, $orange);

    @include for-size(phone-only) {
        @include text($font-family-poppins, 1.125rem, $font-weight-600, $orange);
    }

    @include for-size(phone-landscape) {
        @include text($font-family-poppins, 1.125rem, $font-weight-600, $orange)
    }

    @include for-size(tablet-portrait-up) {
        @include text($font-family-poppins, 1.25rem, $font-weight-600, $orange)
    }
}



.white-title-menu {
    @include text($font-family-playfair-display, 4.5rem, $font-weight-bold, $white);

    @include for-size(phone-only) {
        @include text($font-family-playfair-display, 3rem, $font-weight-bold, $white);
    }

    @include for-size(phone-landscape) {
        @include text($font-family-playfair-display, 2.3rem, $font-weight-bold, $white);
    }

    @include for-size(tablet-portrait-up) {
        @include text($font-family-playfair-display, 2.75rem, $font-weight-bold, $white);
    }

    @include for-size(tablet-landscape-up) {
        @include text($font-family-playfair-display, 3.5rem, $font-weight-bold, $white);
    }

    @include for-size(desktop-up) {
        @include text($font-family-playfair-display, 3.7rem, $font-weight-bold, $white);
        text-align: center;
    }
}

// Styles for button texts.
.main-color-button {
    @include text($font-family-poppins, 1.125rem, $font-weight-600, $dark-blue);
    text-align: center;
}

.text-btn-orange {
    @include text($font-family-poppins, 1rem, $font-weight-600, $orange);
    text-align: center;
}



.title-black {
    @include text($font-family-poppins, 1.5rem, $font-weight-600, $dark-blue);

    @include for-size(phone-only) {
        @include text($font-family-poppins, 1.1rem, $font-weight-600, $dark-blue);
    }

    @include for-size(phone-landscape) {
        @include text($font-family-poppins, 1.18rem, $font-weight-600, $dark-blue);
    }

    @include for-size(tablet-portrait-up) {
        @include text($font-family-poppins, 1.25rem, $font-weight-600, $dark-blue);
    }
}

.title-white {
    @include text($font-family-playfair-display, 4.4rem, $font-weight-bold, $white);
    letter-spacing: normal;

    @include for-size(phone-only) {
        @include text($font-family-playfair-display, 3rem, $font-weight-bold, $white);
        width: 319px;
        letter-spacing: normal;
    }

    @include for-size(phone-landscape) {
        @include text($font-family-playfair-display, 3rem, $font-weight-bold, $white);
        width: 360px;
        letter-spacing: normal;
    }

    @include for-size(tablet-portrait-up) {
        @include text($font-family-playfair-display, 3rem, $font-weight-bold, $white);
        width: 402px;
        letter-spacing: normal;
    }

    @include for-size(tablet-landscape-up) {
        @include text($font-family-playfair-display, 4.5rem, $font-weight-bold, $white);
        letter-spacing: normal;
    }

    @include for-size(desktop-up) {
        @include text($font-family-playfair-display, 4.4rem, $font-weight-bold, $white);
        width: 560px;
        letter-spacing: normal;
    }
}


.text-yellow {
    @include text($font-family-poppins, 1.1rem, $font-weight-600, $yellow);
    text-align: center;
}


.box-title-menud {
    @include text($font-family-poppins, 5rem, $font-weight-600, $yellow);

    @include for-size(phone-only) {
        @include text($font-family-poppins, 5rem, $font-weight-600, $yellow);
    }
}

.font-weight-orange-menud {
    @include text($font-family-poppins, 1.5rem, $font-weight-600, $orange);
    text-align: center;

    @include for-size(phone-only) {
        @include text($font-family-poppins, 1.125rem, $font-weight-600, $orange);
        width: 319px;
        text-align: center;
    }

}

.font-weight-white {
    @include text($font-family-playfair-display, 4.5rem, $font-weight-bold, $white);
    letter-spacing: normal;

    @include for-size(phone-only) {
        @include text($font-family-playfair-display, 3rem, $font-weight-bold, $white);
        line-height: 1;
        letter-spacing: normal;
    }

    @include for-size(phone-landscape) {
        @include text($font-family-playfair-display, 3rem, $font-weight-bold, $white);
        line-height: 1;
        letter-spacing: normal;
    }

    @include for-size(tablet-portrait-up) {
        @include text($font-family-playfair-display, 3.25rem, $font-weight-bold, $white);
        letter-spacing: normal;
        text-align: center;
    }
}

.font-weight-sm-white {
    @include for-size(phone-only) {
        @include text($font-family-playfair-display, 3rem, $font-weight-bold, $white);
        width: 319px;
        line-height: 1;
        letter-spacing: normal;
    }

    @include for-size(phone-landscape) {
        @include text($font-family-playfair-display, 3rem, $font-weight-bold, $white);
        line-height: 1;
        letter-spacing: normal;
    }


}