$yellow:#f6b91b;
$orange:#ff6635;
$dark-blue:#010d1c;
$gray:#e7e7e7;
$dark-gray:#cdcdcd;
$white:#ffffff;
$border-orange:solid 2px #ff6635;
$border-yellow:solid 2px #f6b91b;
$border-dark-blue:solid 2px #010d1c;
$transparent:transparent;
$font-family-playfair-display:'Playfair Display', serif;
$font-family-poppins:'Poppins', sans-serif;
$font-family-nunito-sans:'Nunito Sans', sans-serif;
$font-weight-500:500;
$font-weight-600:600;
$font-weight-normal:normal;
$font-weight-bold:bold;
$text-align-center:center;




