@import '../sass/variables';
@import '../sass/responsive';
@import '../sass/mixin';

/**Styles for the MainSection component of the home page */

.ml-main {
    margin-left: 30%;

    @include for-size(phone-only) {
        margin-left: 7%;
    }

    @include for-size(phone-landscape) {
        margin-left: 6%;
    }

    @include for-size(tablet-portrait-up) {
        margin-left: 2.1rem;
    }

    @include for-size(tablet-landscape-up) {
        margin-left: 5%;
    }

    @include for-size(desktop-up) {
        margin-left: 10%;
    }


}

.m-main-card-menud {
    margin-top: 5%;

    @include for-size(phone-only) {}

    @include for-size(phone-only) {
        margin-top: 5%;
    }

    @include for-size(tablet-portrait-up) {
        margin-top: 5%
    }

    @include for-size(desktop-up) {
        margin-top: 5%;
    }

}

.mt-content-p {
    margin-top: 10%;
}


.ml-content-card {
    margin-left: 10%;

    @include for-size(phone-only) {
        margin-left: 18%;
    }

    @include for-size(phone-landscape) {
        margin-left: 50%;
    }

    @include for-size(tablet-landscape-up) {
        margin-left: 60%;
    }

    @include for-size(desktop-up) {
        margin-left: 40%;
    }
}

/**Styles for the saveTime component of the home page */
.mt-title-white {
    margin-top: 30%;

    @include for-size(phone-only) {
        margin-top: 100%;
    }

    @include for-size(phone-landscape) {
        margin-top: 95%;
    }

    @include for-size(tablet-portrait-up) {
        margin-top: 78%;
    }

    @include for-size(tablet-landscape-up) {
        margin-top: 43%;
    }
}

/** Styles for the meal-plans component of the home page. */

.mt-black-paragraph-menud {
    margin-top: 120px;
}

.mt-rectangular-cards {
    margin-top: 200px;
}

.ml-content {
    margin-left: 5%;

    @include for-size(desktop-up) {
        margin-left: 0;
    }

}

/** Styles for the simplify component of the home page. */
.mt-angle-card {
    margin-top: 30px;
}

.margin-content-negative-cards {
    margin-left: -20px;
}


.ml-content-cards {
    margin-left: 11%;

    @include for-size(phone-only) {
        margin-left: 25%;
    }

    @include for-size(phone-landscape) {
        margin-left: 60%;
    }

    @include for-size(tablet-portrait-up) {
        margin-left: 40%;
    }

    @include for-size(tablet-landscape-up) {
        margin-left: 60%;
    }

    @include for-size(desktop-up) {
        margin-left: 20%;
    }
}

.margin-left-content {
    margin-left: 11%;

    @include for-size(phone-only) {
        margin-left: 0.5%;
    }

    @include for-size(phone-landscape) {
        margin-left: 0.1%;
    }

    @include for-size(tablet-portrait-up) {
        margin-left: 0.5%;
    }

    @include for-size(tablet-landscape-up) {
        margin-left: 0.5%;
    }

    @include for-size(desktop-up) {
        margin-left: 2%;
    }
}


/** Styles for the informationSection component of the grocers page. */
.mt-content-information {
    margin-top: 30%;

    @include for-size(phone-only) {
        margin-top: 0;
    }

    @include for-size(phone-landscape) {
        margin-top: 0;
    }

    @include for-size(tablet-portrait-up) {
        margin-top: 0;
    }

    @include for-size(tablet-landscape-up) {
        margin-top: 0;
    }
}

.mt-content-sm-information {
    @include for-size(phone-only) {
        margin-top: 55%;
    }

    @include for-size(phone-landscape) {
        margin-top: 80%;
    }
}

.mt-content-md-information {
    @include for-size(tablet-portrait-up) {
        margin-top: 80%;
    }

    @include for-size(tablet-landscape-up) {
        margin-top: 54%;
    }
}

/** Styles for the EngageShopper component of the grocers page. */

.mt-box-menud {
    margin-top: 10%;
}

.margins-content-small-text {
    @include for-size(phone-only) {
        margin-top: 29%;
        margin-left: 6%;
    }

    @include for-size(phone-landscape) {
        margin-top: 29%;
    }

}

.ml-sm-content-cards {
    @include for-size(phone-only) {
        margin-left: 30%;
    }

    @include for-size(tablet-portrait-up) {
        margin-left: 45%;
    }

}

/** Styles for the Actionable component of the grocers page. */


.margin-left-invisble-card {
    margin-left: 50%;
}

.margin-content-cards-menud {
    @include for-size(phone-only) {
        margin-left: 9%;
    }

    @include for-size(phone-landscape) {
        margin-left: 40%
    }

    // @include for-size(tablet-portrait-up) {
    //     margin-left: 45%;
    // }

    // @include for-size(tablet-landscape-up) {
    //     margin-left: 40%;
    // }
}

.mr-auto-menud {
    @include for-size(tablet-portrait-up) {
        margin-right: 1rem !important;
    }

    @include for-size(tablet-landscape-up) {
        margin-right: auto !important;
    }


}

/** Styles for the Microsite component of the creators page. */
.margin-microsite {
    margin-top: 40%;

    @include for-size(phone-only) {
        margin-top: 7%;
    }

    @include for-size(phone-landscape) {
        margin-top: 3%;
    }

    @include for-size(tablet-portrait-up) {
        margin-top: 3%;
    }

    @include for-size(tablet-landscape-up) {
        margin-top: 3%;
    }
}

.margin-microsite-paragraphs {
    margin-top: 21%;
    margin-right: 20%;

    @include for-size(phone-only) {
        margin-top: 50%;
        margin-left: 8%;
    }

    @include for-size(phone-landscape) {
        margin-top: 90%;
        margin-left: 4%;
    }

    @include for-size(tablet-portrait-up) {
        margin-top: 80%;
        margin-left: 4%;
    }

    @include for-size(tablet-landscape-up) {
        margin-top: 60%;
        margin-left: 4%;
    }

    @include for-size(desktop-up) {
        margin-top: 25%;
        margin-left: 4%;
    }
}

/** Styles for the ShopperJourney component of the brands page. */
.margin-left-text {
    margin-left: 15%;

    @include for-size(phone-only) {
        margin-left: 6%;
    }

    @include for-size(phone-landscape) {
        margin-left: 5%;
    }

    @include for-size(tablet-portrait-up) {
        margin-left: 4%;
    }

    @include for-size(tablet-landscape-up) {
        margin-left: 3%;
    }

    @include for-size(desktop-up) {
        margin-left: 6.8%;
    }
}


.margin-top-text {
    margin-top: 10%;
}

/** Styles for the simplify component of the home page. */

.mt-sm-content-text {
    @include for-size(phone-only) {
        margin-top: 50%;
    }

}

/** Styles for the mainSection component of the grocers page. */
.ml-content-menud {
    margin-left: 10%;

    @include for-size(phone-only) {
        margin-left: 0%;
    }

    @include for-size(phone-landscape) {
        margin-left: 0;
    }

    @include for-size(tablet-portrait-up) {
        margin-left: 0;
    }

    @include for-size(tablet-landscape-up) {
        margin-left: 0;
    }

    @include for-size(desktop-up) {
        margin-left: 0;
    }
}

.ml-card-rectangle {
    @include for-size(phone-only) {
        margin-left: 12%;
    }

    @include for-size(phone-landscape) {
        margin-left: 46%;
    }

    @include for-size(tablet-landscape-up) {
        margin-left: 60%;
    }

    @include for-size(desktop-up) {
        margin-left: 20%;
    }


}

.mt-content-menud {
    @include for-size(tablet-portrait-up) {
        margin-top: 15%;
    }


}

/** Styles for the RecipeManagement component of the creators page. */
.margin-xs-left-content {
    @include for-size(phone-only) {
        margin-left: 13%;
    }
}

/** Styles for the MenuPhone component */
.margin-top-item-menu {
    @include for-size(phone-only) {
        margin-top: 18%;
    }
}