@import '../sass/variables';
@import '../sass/mixin';
@import '../sass/responsive';

/** Styles for the mainSection component of the home page. */
.background-main-home {
    @include background(url('../../assets/home/background/web/Main.png'), center, 1320px);

    @include for-size(phone-only) {
        @include background(url('../../assets/home/background/mobile/main-phone-home.png'), center, auto)
    }

    @include for-size(phone-landscape) {
        @include background(url('../../assets/home/background/web/Main.png'), center, auto)
    }

    @include for-size(tablet-landscape-up) {
        @include background(url('../../assets/home/background/web/Main.png'), center, 1100px)
    }
}

/**Styles for the saveTime component of the home page */
.background-savetime {
    @include background(url('../../assets/home/background/web/saveTime.png'), initial, 1520px);
    margin-top: -35%;
    z-index: 1;

    @include for-size(phone-only) {
        @include background(url('../../assets/home/background/mobile/savetime-phone-home.png'), initial, 1090px);
        margin-top: -90%;
        z-index: 1;
    }

    @include for-size(phone-landscape) {
        @include background(url('../../assets/home/background/web/saveTime.png'), initial, 1350px);
        margin-top: -94%;
        z-index: 1;
    }

    @include for-size(tablet-portrait-up) {
        @include background(url('../../assets/home/background/web/saveTime.png'), initial, 1350px);
        margin-top: -73%;
        z-index: 1;
    }

    @include for-size(tablet-landscape-up) {
        @include background(url('../../assets/home/background/web/saveTime.png'), initial, 1250px);
        margin-top: -50%;
        z-index: 1;
    }

    @include for-size(desktop-up) {
        @include background(url('../../assets/home/background/web/saveTime.png'), initial, 1352px);
        margin-top: -50%;
        z-index: 1;

    }
}

/** Styles for the meal-plans component of the home page. */
.background-meal-plans {
    height: 1410px;

    @include for-size(phone-only) {
        height: auto;
    }

    @include for-size(phone-landscape) {
        height: auto;
    }

    @include for-size(tablet-portrait-up) {
        height: auto;
    }

    @include for-size(tablet-landscape-up) {
        height: auto;
    }

    @include for-size(desktop-up) {
        height: auto;
    }
}

/** Styles for the simplify component of the home page. */
.background-simplify {
    @include background(url('../../assets/home/background/web/simplify.png'), initial, 1430px);
    display: flex;
    flex-direction: column;

    @include for-size(phone-only) {
        @include background(url('../../assets/home/background/web/simplify.png'), initial, 1720px);
    }

    @include for-size(phone-landscape) {
        @include background(url('../../assets/home/background/web/simplify.png'), initial, 1000px);
    }

    @include for-size(tablet-portrait-up) {
        @include background(url('../../assets/home/background/web/simplify.png'), initial, 1070px)
    }


}

/** Styles for the downloadMenud component of the home page. */
.background-download-menud {
    @include background(url('../../assets/home/background/web/download-menu.png'),center,768px);
    display: flex;
    @include for-size(phone-only) {
        @include background(url('../../assets/home/background/mobile/footer-home-mobile-min.png'), initial,768px) 
    }
    @include for-size(phone-landscape) {
        @include background(url('../../assets/home/background/web/download-menu.png'),center,500px)
    }
}
