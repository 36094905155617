@import '../sass/variables';
@import '../sass/responsive';
@import '../sass/mixin';

.over-flow-menu-d {
    @include overflow(auto);
}

.over-flow-menu-d::-webkit-scrollbar {
    width: 2px;
    display: none;
}