@import '../sass/mixin';
@import '../sass/variables';
@import '../sass/responsive';

.main-paragraph-menud {
    @include text($font-family-playfair-display, 4.5rem, $font-weight-bold, $white);
    width: 620px;
    letter-spacing: normal;

    @include for-size(phone-only) {
        @include text($font-family-playfair-display, 3rem, $font-weight-bold, $white);
        width: 319px;
    }

    @include for-size(phone-landscape) {
        @include text($font-family-playfair-display, 3.25rem, $font-weight-bold, $white);
        width: 450px;
    }

    @include for-size(tablet-portrait-up) {
        @include text($font-family-playfair-display, 3rem, $font-weight-bold, $white);
        width: 419px;
    }
}

// Styles for dark blue texts
.black-paragraph-menud {
    @include text($font-family-playfair-display, 4.3rem, $font-weight-bold, $dark-blue);

    @include for-size(phone-only) {
        @include text($font-family-playfair-display, 3rem, $font-weight-bold, $dark-blue);
        width: 319px;
    }

    @include for-size(phone-landscape) {
        @include text($font-family-playfair-display, 3rem, $font-weight-bold, $dark-blue);
    }

    @include for-size(tablet-portrait-up) {
        @include text($font-family-playfair-display, 3rem, $font-weight-bold, $dark-blue);
    }

    @include for-size(desktop-up) {
        @include text($font-family-playfair-display, 3.6rem, $font-weight-bold, $dark-blue);
        width: 460px;
    }

}



.text-white-information {
    @include text($font-family-poppins, 7.5rem, $font-weight-600, $white);
    letter-spacing: normal;

    @include for-size(phone-only) {
        @include text($font-family-poppins, 5rem, $font-weight-600, $white);
        letter-spacing: normal;
    }

    @include for-size(phone-landscape) {
        @include text($font-family-poppins, 5rem, $font-weight-600, $white);
        letter-spacing: normal;
    }

    @include for-size(tablet-portrait-up) {
        @include text($font-family-poppins, 5rem, $font-weight-600, $white);
        letter-spacing: normal;
    }
}

.text-white-download-menud {
    @include text($font-family-poppins, 1rem, $font-weight-600, $white);
    text-align: center;
}

.paragraph-bold-menud {
    @include text($font-family-playfair-display, 2.5rem, $font-weight-bold, $dark-blue);
    width: 520px;
    letter-spacing: normal;

    @include for-size(phone-only) {
        @include text($font-family-playfair-display, 2rem, $font-weight-bold, $dark-blue);
        width: 319px;
        letter-spacing: normal;
    }

    @include for-size(phone-landscape) {
        @include text($font-family-playfair-display, 2rem, $font-weight-bold, $dark-blue);
        width: 319px;
        letter-spacing: normal;
    }

    @include for-size(tablet-portrait-up) {
        @include text($font-family-playfair-display, 2rem, $font-weight-bold, $dark-blue);
        width: 400px;
        letter-spacing: normal;
    }
}

.paragraph-small-bold-menud {
    @include text($font-family-poppins, 1.1rem, $font-weight-500, $dark-blue);
    letter-spacing: 0.75px;
}

.text-black-menu-cards {
    @include text($font-family-nunito-sans, 1.1rem, $font-weight-normal, $dark-blue);
    width: 480px;
    line-height: 1.33;
    letter-spacing: 0.4px;

    @include for-size(phone-only) {
        @include text($font-family-nunito-sans, 1.1rem, $font-weight-normal, $dark-blue);
        width: 319px;
        line-height: 1.33;
        letter-spacing: 0.4px;
    }

    @include for-size(phone-landscape) {
        @include text($font-family-nunito-sans, 1.1rem, $font-weight-normal, $dark-blue);
        width: 319px;
        line-height: 1.33;
        letter-spacing: 0.4px;
    }

    @include for-size(tablet-portrait-up) {
        @include text($font-family-nunito-sans, 1.1rem, $font-weight-normal, $dark-blue);
        width: 389px;
        line-height: 1.33;
        letter-spacing: 0.4px;
    }

    @include for-size(desktop-up) {
        @include text($font-family-nunito-sans, 1.1rem, $font-weight-normal, $dark-blue);
        width: 359px;
        line-height: 1.33;
        letter-spacing: 0.4px;
    }
}

.font-weight-bold-menud {
    @include text($font-family-poppins, 1.5rem, $font-weight-600, $dark-blue);

    @include for-size(phone-only) {
        @include text($font-family-poppins, 1.125rem, $font-weight-600, $dark-blue);
    }

    @include for-size(phone-landscape) {
        @include text($font-family-poppins, 1.125rem, $font-weight-600, $dark-blue);
    }

    @include for-size(tablet-portrait-up) {
        @include text($font-family-poppins, 1.125rem, $font-weight-600, $dark-blue);
    }
}



.paragraph-shopper {
    @include text($font-family-nunito-sans, 1.125rem, $font-weight-normal, $dark-blue);
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.4px;
}

.paragraph-white-develop {
    @include text($font-family-nunito-sans, 1.125rem, $font-weight-normal, $white);
    line-height: 1.33;
    letter-spacing: 0.4px;
    text-align: center;

    @include for-size(phone-only) {
        @include text($font-family-nunito-sans, 1.125rem, $font-weight-normal, $white);
        width: 319px;
        line-height: 1.33;
        letter-spacing: 0.4px;
        text-align: center;
    }

    @include for-size(phone-landscape) {
        @include text($font-family-nunito-sans, 1.125rem, $font-weight-normal, $white);
        width: 480px;
        line-height: 1.33;
        letter-spacing: 0.4px;
        text-align: center;
    }

    @include for-size(tablet-portrait-up) {
        @include text($font-family-nunito-sans, 1.125rem, $font-weight-normal, $white);
        width: 600px;
        line-height: 1.33;
        letter-spacing: 0.4px;
        text-align: center;
    }
}

.paragraph-black-content {
    @include for-size(phone-only) {
        @include text($font-family-nunito-sans, 0.875rem, $font-weight-normal, $dark-blue);
        line-height: 1.29;
        letter-spacing: normal;
    }
}

.box-sm-paragraph-menu {
    @include for-size(phone-only) {
        @include text($font-family-nunito-sans, 0.875rem, $font-weight-normal, $dark-blue);
        width: 319px;
        line-height: 1.29;
        letter-spacing: normal;
    }

    @include for-size(phone-landscape) {
        @include text($font-family-nunito-sans, 0.875rem, $font-weight-normal, $dark-blue);
        width: 180px;
        text-align: center;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
    }

}

.paragraph-small-icon {
    @include for-size(phone-only) {
        @include text($font-family-nunito-sans, 0.875rem, $font-weight-normal, $dark-blue);
        width: 192px;
        line-height: 1.29;
        letter-spacing: normal;  
    }
    @include for-size(phone-landscape) {
        @include text($font-family-nunito-sans, 0.875rem, $font-weight-normal, $dark-blue);
        line-height: 1.29;
        text-align: center;
        letter-spacing: normal;
    }

}