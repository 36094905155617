@import '../sass/variables';
@import '../sass/responsive';
@import '../sass/mixin';

.box-menud {
    @include box(296px, 296px, 16px, $border-dark-blue);

    @include for-size(phone-only) {
        @include box(240px, 240px, 16px, $border-dark-blue);
    }

    @include for-size(phone-landscape) {
        @include box(240px, 240px, 16px, $border-dark-blue);
    }

    @include for-size(tablet-portrait-up) {
        @include box(240px, 240px, 16px, $border-dark-blue);
    }
}

.square-box {
    @include box(336px, 387px, 24px, $border-dark-blue);

    @include for-size(phone-only) {
        @include box(319px, 272px, 24px, $border-dark-blue);
    }

    @include for-size(phone-landscape) {
        @include box(319px, 272px, 24px, $border-dark-blue);
    }
}

.square-box-header {
    @include box-header(relative, 80px, 20px, 20px, $yellow, $border-dark-blue);

    @include for-size(phone-only) {
        @include box-header(relative, 56px, 20px, 20px, $yellow, $border-dark-blue); 
    }
    @include for-size(phone-landscape) {
        @include box-header(relative, 56px, 20px, 20px, $yellow, $border-dark-blue); 
    }
}