@import '../sass/variables';
@import '../sass/responsive';
@import '../sass/mixin';

/** Styles for the mainSection component of the brands page. */
.background-mainsection-brands {
    @include background(url('../../assets/brands/background/web/main-brand.png'), initial, 1250px);
    position: relative;

    @include for-size(phone-only) {
        @include background(url('../../assets/brands/background/phone/main-phone.png'), initial, auto)
    }

    @include for-size(phone-landscape) {
        @include background(url('../../assets/brands/background/web/main-brand.png'), initial, auto);
    }

    @include for-size(tablet-portrait-up) {
        @include background(url('../../assets/brands/background/web/main-brand.png'), left, auto);
    }

    @include for-size(tablet-landscape-up) {
        @include background(url('../../assets/brands/background/web/main-brand.png'), initial, 920px);
    }

    @include for-size(desktop-up) {
        @include background(url('../../assets/brands/background/web/main-brand.png'), initial, 1100px);
    }
}

.background-angle-yellow {
    width: 100%;
    margin-top: -32%;
    position: relative;

    @include for-size(phone-only) {
        margin-top: -60%;
    }

    @include for-size(phone-landscape) {
        margin-top: -29%;
    }

    @include for-size(tablet-portrait-up) {
        margin-top: -25%;
    }

    @include for-size(tablet-landscape-up) {
        margin-top: -24%;
    }

    @include for-size(desktop-up) {
        margin-top: -28%;
    }
}

/** Styles for the curation component of the brands page. */
.background-curation {
    @include background(url('../../assets/brands/background/web/curation.png'), initial, 1810px);

    @include for-size(phone-only) {
        @include background(url('../../assets/brands/background/phone/curation_cel.png'), initial, 1300px);
    }

    @include for-size(phone-landscape) {
        @include background(url('../../assets/brands/background/web/curation.png'), initial, 900px);
    }

    @include for-size(tablet-portrait-up) {
        @include background(url('../../assets/brands/background/web/curation.png'), initial, 1000px);
    }

    @include for-size(tablet-landscape-up) {
        @include background(url('../../assets/brands/background/web/curation.png'), initial, 1600px);
    }

    @include for-size(desktop-up) {
        @include background(url('../../assets/brands/background/web/curation.png'), initial, 1400px);
    }
}

/** Styles for the ShopperJourney component of the brands page. */
.background-shooperjourney {
    height: 2100px;

    @include for-size(phone-only) {
        height: 2650px;
    }

    @include for-size(phone-landscape) {
        height: 2450px;
    }

    @include for-size(tablet-portrait-up) {
        height: 1710px;
    }

    @include for-size(tablet-landscape-up) {
        height: 2350px;
    }
}

/** Styles for the Develop component of the brands page. */
.background-develop {
    @include background(url('../../assets/brands/background/web/develop.png'), initial, 892px);
    display: flex;
    flex-direction: column;

    @include for-size(phone-only) {
        @include background(url('../../assets/brands/background/phone/develop.png'), initial, 984px);
    }

}