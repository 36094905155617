@import '../sass/variables';
@import '../sass/responsive';
@import '../sass/mixin';

// Styles for icon titles
.icon-title {
    @include text($font-family-poppins, 1.5rem, $font-weight-600, $dark-blue);
    height: 48px;
    width: 100%;

    @include for-size(phone-only) {
        @include text($font-family-poppins, 1.1rem, $font-weight-600, $dark-blue);
    }

    @include for-size(phone-landscape) {
        @include text($font-family-poppins, 1.1rem, $font-weight-600, $dark-blue);
        height: 38px;
    }

    @include for-size(tablet-portrait-up) {
        @include text($font-family-poppins, 1.25rem, $font-weight-600, $dark-blue);
    }
}

.small-text-black {
    @include text($font-family-poppins, 1.1rem, $font-weight-500, $dark-blue);
    letter-spacing: 0.75px;
    text-align: center;
}

.title-small-icon {
    @include text($font-family-poppins, 1.5rem, $font-weight-600, $dark-blue);

    @include for-size(phone-only) {
        @include text($font-family-poppins, 1.1rem, $font-weight-600, $dark-blue);
    }

    @include for-size(phone-landscape) {
        @include text($font-family-poppins, 1.1rem, $font-weight-600, $dark-blue);
        text-align: center;
    }

    @include for-size(tablet-portrait-up) {
        @include text($font-family-poppins, 1.1rem, $font-weight-600, $dark-blue);
    }

}

.icon-white {
    @include text($font-family-nunito-sans, 1.1rem, $font-weight-normal, $white);
    line-height: 1.33;
    letter-spacing: 0.4px;
    text-align: center;
    @include for-size(phone-only) {
        @include text($font-family-nunito-sans, 0.875rem, $font-weight-normal, $white);
        line-height: 1.29;
        letter-spacing: normal;
        text-align: center;
    }
}

.icon-white-develop{
    @include for-size(phone-only) {
        @include text($font-family-nunito-sans, 0.875rem, $font-weight-normal, $white);
        width: 192px;
        height: 36px;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: left;
    }
   
}
.title-orange-icon{
    @include for-size(phone-only) { 
        @include text($font-family-poppins, 1.125rem, $font-weight-600, $orange); 
        width: 192px;
        text-align: left;
    }
}