@import '../src/components/sass/variables';
@import '../src/components/sass/background-home';
@import '../src/components/sass/background-grocers';
@import '../src/components/sass/background-creator';
@import '../src/components/sass/background-brand';
@import '../src/components/sass/footer';
@import '../src/components/sass/mixin';
@import '../src/components/sass/nav-link';
@import '../src/components/sass/nav-link';
@import '../src/components/sass/font-face-title';
@import '../src/components/sass/font-face-paragraph';
@import '../src/components/sass/font-face-icon';
@import '../src/components/sass/font-face-box';
@import '../src/components/sass/font-face-italic-paragraph';
@import '../src/components/sass/cards';
@import '../src/components/sass/button';
@import '../src/components/sass/box';
@import '../src/components/sass/overflow';
@import '../src/components/sass/dimension-image';
@import '../src/components/sass/margins.scss';
@import '../src/components/sass/margins-negative';

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.z-index-arrow {
  z-index: 2;
}

/** Styles for the socially component of the home page. */

.rectangle-line {
  width: 32px;
  height: 2px;
  background-color: #f6b91b;
}


@media (max-width: 575px) {

  /** Styles for the MenuPhone component */
  .background-menu-phone {
    background-color: #010d1c;
    position: fixed;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 12;
    height: 812px;
  }

  .logo {
    width: 80%
  }

}

/* JOBS */
.content--jobs {
	max-width: 640px;
	width: 100%;
	margin: 20px auto;
}

.jobs--callout {
	font-weight: bold;
}

.jobs--job {
	display: inline-block;
	width: 49%;
	margin-bottom: 20px;
	padding: 20px;
	border: 1px solid #ccc;
	box-sizing: border-box;
	padding-top: 40%; /* 1:1 Aspect Ratio */
  	position: relative;
}

.job--content {
	position: absolute;
	top: 10px;
	left: 10px;
	right: 10px;
	bottom: 10px;
}

.jobs--job:nth-child(odd) {
	margin-right: 2%
}

/* TERMS OF SERVICE */

.content--terms {
	max-width: 640px;
	margin: 20px auto;
}

.content--terms h2 {
	margin-top: 4rem;
	margin-bottom: 1rem;
}

.content--terms h3 {
	margin-top: 2rem;
	margin-bottom: 1rem;
	font-size: 24px;
}

.content--terms ul {
	padding-left: 4rem;
}

.content--terms li {
	padding-left: 1rem;
}

.container--tips {
	max-width: 1024px;
	margin: 0 auto;
	.container--table-of-contents {
		display: flex;
	}
}

