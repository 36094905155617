@import '../sass/variables';
@import '../sass/mixin';
@import '../sass/responsive';

/** Styles for the MainSection component of the creators page. */
.background-mainsection-creator {
    @include background(url('../../assets/creators/background/web/Creator_mainSection.png'), initial, 1090px);

    @include for-size(phone-only) {
        @include background(url('../../assets/creators/background/phone/MainSection-phone.png'), initial, auto)
    }

    @include for-size(phone-landscape) {
        @include background(url('../../assets/creators/background/web/Creator_mainSection.png'), initial, auto);
    }

    @include for-size(tablet-portrait-up) {
        @include background(url('../../assets/creators/background/web/Creator_mainSection.png'), initial, 1050px);
    }

    @include for-size(tablet-landscape-up) {
        @include background(url('../../assets/creators/background/web/Creator_mainSection.png'), initial, 920px);
    }
}

/** Styles for the Microsite component of the creators page. */
.background-microsite {
    @include background(url('../../assets/creators/background/web/Microsite.png'), initial, 1399px);
    margin-top: -30%;

    @include for-size(phone-only) {
        @include background(url('../../assets/creators/background/phone/microsite-phone.png'), initial, 1095px);
    }

    @include for-size(phone-landscape) {
        @include background(url('../../assets/creators/background/web/Microsite.png'), initial, 1600px);
        margin-top: -90%;
    }

    @include for-size(tablet-portrait-up) {
        @include background(url('../../assets/creators/background/web/Microsite.png'), initial, 1600px);
        margin-top: -80%;

    }

    @include for-size(tablet-landscape-up) {
        @include background(url('../../assets/creators/background/web/Microsite.png'), initial, 1850px);
        margin-top: -60%;
    }

    @include for-size(desktop-up) {
        @include background(url('../../assets/creators/background/web/Microsite.png'), initial, 1399px);
        margin-top: -40%;
    }
}

/** Styles for the CapturingRecipes component of the creators page. */
.background-capturing {
    height: 300px;

    @include for-size(phone-only) {
        height: auto;
    }

    @include for-size(phone-landscape) {
        height: auto;
    }

    @include for-size(tablet-portrait-up) {
        height: auto;
    }
}

.creators--importBlock {
    width: 30%;
}

/** Styles for the WhiteLabel  component of the creators page. */
.background-white-label {
    @include background(url('../../assets/creators/background/web/whiteLabel.png'), initial, 2000px);

    @include for-size(phone-only) {
        @include background(url('../../assets/creators/background/phone/whiteLabel-phone.png'), initial, 1850px)
    }

    @include for-size(phone-landscape) {
        @include background(url('../../assets/creators/background/web/whiteLabel.png'), initial, 1200px);
    }

    @include for-size(tablet-portrait-up) {
        @include background(url('../../assets/creators/background/web/whiteLabel.png'), initial, 1300px);
    }
}

/** Styles for the RecipeManagement component of the creators page. */
.background-recipe-management {
    height: 1620px;

    @include for-size(phone-only) {
        height: 1360px;
    }

    @include for-size(phone-landscape) {
        height: 1520px;
    }

    @include for-size(tablet-portrait-up) {
        height: 1360px;
    }

    @include for-size(tablet-landscape-up) {
        height: 1420px;
    }

    @include for-size(desktop-up) {
        height: 730px;
    }

}

/** Styles for the TurnkeyInteractive component of the creators page. */
.background-turnkey-iteractive {
    @include background(url('../../assets/creators/background/web/Creator_interactive.png'), initial, 768px);
    display: flex;

    @include for-size(phone-only) {
        @include background(url('../../assets/creators/background/phone/turnkey-phone.png'), initial, 840px)
    }
}