@mixin for-size($size) {

    /* Extra small devices (portrait phones, less than 576px) */
    @if $size==phone-only {
        @media (max-width: 575px) {
            @content;
        }
    }

    /* Small devices (landscape phones, 576px and up) */
    @else if($size==phone-landscape) {
        @media(min-width: 576px) and (max-width: 767px) {
            @content;
        }
    }

    /* Medium devices (tablets, 768px and up) */
    @else if $size==tablet-portrait-up {
        @media (min-width: 768px) and (max-width: 991px) {
            @content;
        }

    }
    /* Large devices (desktops, 992px and up) */
    @else if $size==tablet-landscape-up {
        @media (min-width: 992px) and (max-width: 1199px) {
            @content;
        }
    }
/* Extra large devices (large desktops, 1200px and up) */
    @else if $size==desktop-up {
        @media (min-width: 1200px) and (max-width:1600px) {
            @content;
        }
    }
}