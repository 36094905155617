@import '../sass/mixin';
@import '../sass/variables';
@import '../sass/responsive';

.italic-text-black {
    @include text($font-family-playfair-display, 2.5rem, $font-weight-bold, $dark-blue);
    font-style: italic;
    text-align: center;
    letter-spacing: normal;
    line-height: 1.2;

    @include for-size(phone-only) {
        @include text($font-family-playfair-display, 1.5rem, $font-weight-bold, $dark-blue);
        font-style: italic;
        letter-spacing: normal;
        text-align: center;
    }

    @include for-size(phone-landscape) {
        @include text($font-family-playfair-display, 1.5rem, $font-weight-bold, $dark-blue);
        font-style: italic;
        letter-spacing: normal;
        text-align: center;
    }
}


.italic-text-white-menud {
    @include text($font-family-playfair-display, 4.5rem, $font-weight-bold, $white);
    font-style: italic;
    letter-spacing: normal;
    text-align: center;

    @include for-size(phone-only) {
        @include text($font-family-playfair-display, 3rem, $font-weight-bold, $white);
        font-style: italic;
        letter-spacing: normal;
        text-align: center;
    }

    @include for-size(phone-landscape) {
        @include text($font-family-playfair-display, 3rem, $font-weight-bold, $white);
        font-style: italic;
        letter-spacing: normal;
        text-align: center;
    }

    @include for-size(tablet-portrait-up) {
        @include text($font-family-playfair-display, 3.8rem, $font-weight-bold, $white);
        font-style: italic;
        letter-spacing: normal;
        text-align: center;
    }
}