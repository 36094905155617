@import '../sass/variables';
@import '../sass/responsive';
@import '../sass/mixin';

/**Styles for the MainSection component of the home page */

.negative-margin-card-content {
    margin-left: -30%;

    @include for-size(phone-only) {
        margin-left: -15%;
    }

    @include for-size(tablet-landscape-up) {
        margin-left: -50%;
    }
}

/** Styles for the meal-plans component of the home page. */
.mt-negative-rectangular-cards {
    margin-top: -15%;
}

/** Styles for the Actionable component of the grocers page. */
.m-negative-paragraph {
    position: relative;
    margin-right: 22%;

    @include for-size(tablet-portrait-up) {
    }

    @include for-size(tablet-landscape-up) {
    }

    @include for-size(desktop-up) {
        position: relative;
        margin-right: 18%;
    }

}

.m-negative-card-menud {
    margin-top: -20%;
    margin-right: -80%;
    z-index: 5;

    @include for-size(phone-only) {
        margin-top: 10%;
    }

    @include for-size(phone-landscape) {
        margin-top: 10%;
    }

    @include for-size(tablet-portrait-up) {
        margin-top: -38%;
        margin-right: -40%;
    }

    @include for-size(tablet-landscape-up) {
        margin-top: -40%;
        margin-right: -40%;
    }

    @include for-size(desktop-up) {
        width: 100%;
        margin-top: -20%;
        white-space: nowrap;
        margin-right: -50%;
        position: relative;
        z-index: 5
    }
}

.margin-content-cards {
    margin-left: -30%;

    @include for-size(phone-only) {
        margin-right: 9%;
    }

    @include for-size(phone-landscape) {
        margin-left: -1%;
    }

    @include for-size(tablet-portrait-up) {
        margin-left: -40%;
    }

    @include for-size(tablet-landscape-up) {
        margin-left: -46%;

    }

    @include for-size(desktop-up) {
        margin-left: -40%;
    }
}

/** Styles for the Microsite component of the creators page. */
.position-card-menud {
    margin-top: -40%;
    margin-left: 50%;

    @include for-size(phone-only) {
        margin-top: -60%;
        margin-left: 45%;
    }

    @include for-size(phone-landscape) {
        margin-left: 26%;
        margin-top: -30%;
    }

    @include for-size(tablet-portrait-up) {
        margin-left: 20%;
        margin-top: -25%;
    }
}

/** Styles for the RecipeManagement component of the creators page. */
.margin-negative-rectangle-card {
    margin-top: -16%;

    @include for-size(phone-only) {
        margin-top: 5%;
        margin-left: -6%;
    }

    @include for-size(phone-landscape) {
        margin-top: 3%;
    }

    @include for-size(tablet-portrait-up) {
        margin-top: 6%;
        margin-left: -2%;
    }

    @include for-size(tablet-landscape-up) {
        margin-top: 6%;
        margin-left: -2%;
    }

    @include for-size(desktop-up) {
        margin-top: -18%;
    }
}

.margin-top-negative {
    margin-top: -10%;
    margin-left: 7%;

    @include for-size(phone-only) {
        margin-top: 14%;
        margin-left: 6%;
    }

    @include for-size(phone-landscape) {
        margin-top: 10%;
        margin-left: 2%;
    }

    @include for-size(tablet-portrait-up) {
        margin-top: 10%;
        margin-left: 2%;
    }

    @include for-size(tablet-landscape-up) {
        margin-top: 8%;
        margin-left: 2%;
    }

    @include for-size(desktop-up) {
        margin-top: -12%;
        margin-left: 2%;
    }
}

/** Styles for the mainSection component of the brands page. */
.position-card-brands {
    margin-top: -40%;
    margin-left: 22%;

    @include for-size(phone-only) {
        margin-top: -55%;
        margin-left: 53%;
    }

    @include for-size(phone-landscape) {
        margin-left: 70%;
        margin-top: -35%;
    }

    @include for-size(tablet-portrait-up) {
        margin-top: -60%;
    }

    @include for-size(tablet-landscape-up) {
        margin-left: 80%;
        margin-top: -40%;
    }

    @include for-size(desktop-up) {
        margin-left: 40%;
        margin-top: -40%;
    }
}

/** Styles for the ShopperJourney component of the brands page. */
.position-card-shopper {
    margin-top: -40%;
    margin-left: 55%;

    @include for-size(phone-only) {
        margin-top: -60%;
        margin-left: 50%;
    }

    @include for-size(phone-landscape) {
        margin-left: 20%;
        margin-top: -35%;
    }

    @include for-size(tablet-portrait-up) {
        margin-top: -30%;
        margin-left: 20%;
    }

    @include for-size(tablet-landscape-up) {
        margin-left: 50%;
        margin-top: -35%;
    }


}

/** Styles for the mainSection component of the grocers page. */
.margins-content-card-rectangle {
    @include for-size(phone-only) {
        margin-left: -5%;
    }

    @include for-size(tablet-portrait-up) {
        margin-top: 42%;
        margin-left: -80%;
    }

    @include for-size(tablet-landscape-up) {
        margin-left: -60%;
    }

    @include for-size(desktop-up) {
        margin-left: -80%;
    }

}

/** Styles for the simplify component of the home page. */
.m-negative-card {
    @include for-size(phone-only) {
        margin-left: -30%;
    }

    @include for-size(phone-landscape) {
        margin-left: -80%;
    }

    @include for-size(tablet-portrait-up) {
        margin-left: -80%;
    }

    @include for-size(tablet-portrait-up) {
        margin-left: -25.5%;
    }

    @include for-size(tablet-landscape-up) {
        margin-left: -41%;
    }

    @include for-size(desktop-up) {
        margin-left: -20%;
    }
}